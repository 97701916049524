import React, { useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';

import { odooClient } from './Client';
import { Dashboard } from './Dashboard';

const url = 'https://amtg.noosk.fr';
const proxyUrl = 'https://api.odoo-admin.serin.io'

function App() {
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [client, setClient] = React.useState<odooClient>(new odooClient(url, proxyUrl));

  // useEffect(() => {
  //   var _client = new odooClient(url, proxyUrl);
  //   _client.authenticate('amtg', login, password).then((session) => {
  //     if (session.uid === undefined) return;
  //     setClient(_client);
  //   });
  // },[])

  if (client.session) { return <Dashboard client={client} /> }
  return (
    <form>
      <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <button onClick={(e:React.MouseEvent) => {
        e.preventDefault();
        var _client = new odooClient(url, proxyUrl);
        _client.authenticate('amtg', login, password).then((session) => {
          console.log(session);
          if (session.uid === undefined) return;
          setClient(_client);
          }).catch((e) => {console.log(e); return {}})
        
      }}>Login</button>
    </form>
  )
}

export default App;
