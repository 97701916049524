import axios from 'axios';
import { Payment } from './Interfaces';

export class odooSession {
    id?: string
    uid: number;
    db: string;
    context: Record<string, any>;
    is_admin: boolean;
    is_internal_user: boolean;
    is_system: boolean;
    name: string
    partner_display_name: string
    partner_id: number
    allowed_companies: Record<number, Record<string, any>>
    current_company: number



    constructor(info: Record<string, any>) {
        this.uid = info.uid;
        this.context = info.user_context;
        this.db = info.db;
        this.is_admin = info.is_admin;
        this.is_internal_user = info.is_internal_user;
        this.is_system = info.is_system;
        this.name = info.name;
        this.partner_display_name = info.partner_display_name;
        this.partner_id = info.partner_id;
        this.allowed_companies = info.user_companies.allowed_companies;
        this.current_company = info.user_companies.current_company;
    }
}

export class odooClient {
    proxyUrl: string;
    baseUrl: string;
    session?: odooSession;
    private rpcHeaders: Record<string, any> = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }

    constructor(baseUrl: string, proxyUrl?: string) {
        this.baseUrl = baseUrl;
        this.proxyUrl = proxyUrl || baseUrl;
    }

    async call({ path, headers, params }: { path: string, headers?: Record<string, any>, params?: Record<string, any> }): Promise<any> {
        return axios.post(this.proxyUrl, {
            url: this.baseUrl + path,
            headers: { ...this.rpcHeaders, ...headers },
            params: params || {},
        }).then((res) => {
            if (path == '/web/session/authenticate') {
                this.session = new odooSession(res.data.data.result);
                const set_cookie: string = res.data.headers['set-cookie'];
                this.session.id = set_cookie.match(/session_id=([^;]+)/)![1]
                this.rpcHeaders['Cookie'] = 'session_id=' + this.session.id + ';';
                return this.session;
            }
            if (res.data.data.error) {
                console.error(res.data.data.error)
            }
            return res.data.data;
        })
    }

    async kw({ params }: { params: Record<string, any> }): Promise<any> {
        return this.call({ path: '/web/dataset/call_kw', params: params }).then((res) => {
            return res.result;
        })
    }

    async authenticate(db: string, login: string, password: string): Promise<odooSession> {
        return this.call({ path: '/web/session/authenticate', params: { db: db, login: login, password: password } }).then((res) => {
            return res;
        })
    }

    async payments(from?: number, to?: number): Promise<Payment[]> {
        return this.call({ path: '/dashboard/payments', headers: { 'Content-Type': 'application/json' }, params: { 'from': from, 'to': to } }).then((res) => {
            return res.result;
        }).catch((err) => {
            console.error(err);
            return [];
        })
    }

    async subscriptions(from?: number, to?: number): Promise<any> {
        return await this.call({ path: '/dashboard/subscriptions', headers: { 'Content-Type': 'application/json' }, params: { 'from': from, 'to': to } }).then((res) => {
            return res.result;
        }).catch((err) => {
            console.error(err);
            return [];
        })
    }


}