import React from 'react';
import { odooClient } from './Client';

export function toTimestamp(date: Date) {
    return date.getTime() / 1000;
}

export function fromTimestamp(timestamp: number) {
    return new Date(timestamp * 1000);
}

export interface StripeObject {
    id: string;
    created: number;
    description?: string;
}

interface Customer {
    address?: {
        city?: string;
        country?: string;
        line1?: string;
        line2?: string;
        postal_code?: string;
        state?: string;
    }
    email: string;
    name: string;
    phone?: string;
}

export interface Subscription extends StripeObject {
    current_period_end: number;
    current_period_start: number;
    customer: Customer;
    start_date: number;
    status: "active"|"incomplete_expired"|string;
    items:{
        data:{
          plan:{
            nickname: string;
          }  
        }[]
    }
    plan: {
        nickname: string;
    }
}

export interface Payment extends StripeObject {
    amount: number;
    billing_details: Customer
    failure_message?: string;
    metadata: Record<string, any>;
    status: "succeeded"|"failed"|string;
    transfer_data?: {
        destination: string;
        amount: number;
    }
}