import React, { useEffect } from 'react';
import { odooClient } from './Client';
import { Payment, Subscription, fromTimestamp } from './Interfaces';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';  // Import the 'AdapterDayjs' module
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

type DashboardType = 'payments' | 'subscriptions';

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

export function Dashboard({ client }: { client: odooClient }) {
    const [dashboardType, setDashboardType] = React.useState<DashboardType>('subscriptions');
    const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs(oneMonthAgo));
    const [toDate, setToDate] = React.useState<Dayjs>(dayjs(Date.now()));
    const [search, setSearch] = React.useState<string>('');
    return (


        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ margin: 20 }}>
                <h1>Dashboard</h1>
                <ToggleButtonGroup value={dashboardType} exclusive onChange={(e: React.MouseEvent, value: DashboardType) => setDashboardType(value)}>
                    <ToggleButton value='payments'>Payments</ToggleButton>
                    <ToggleButton value='subscriptions'>Subscriptions</ToggleButton>
                </ToggleButtonGroup>
                <div style={{ margin: 5 }}>
                    <DatePicker
                        sx={{ margin: 5 }}
                        label="De"
                        value={fromDate}
                        onChange={(newValue) => setFromDate(newValue)}
                    />
                    <DatePicker
                        sx={{ margin: 5 }}
                        label="A"
                        value={toDate}
                        onChange={(newValue) => setFromDate(newValue ?? toDate)}
                    />
                    <Button variant='outlined' onClick={() => {
                        setFromDate(null)
                        // setToDate(null)
                    }}>x</Button>
                    <Input
                        sx={{ margin: 5 }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                {
                    dashboardType === 'payments' && <PaymentsDashboard client={client} filters={{ from: fromDate, to: toDate, search: search }} />
                }
                {
                    dashboardType === 'subscriptions' && <SubscriptionsDashboard client={client} filters={{ from: fromDate, to: toDate, search: search }} />
                }
            </div>
        </LocalizationProvider>


    )
}

interface Filters {
    from: Dayjs | null;
    to: Dayjs | null;
    search: string;
}

export function PaymentsDashboard({ client, filters }: { client: odooClient, filters: Filters }) {
    const [payments, setPayments] = React.useState<Payment[]>([]);
    const [activePayments, setActivePayments] = React.useState<Payment[]>([]);

    React.useEffect(() => {
        client.payments(filters.from?.unix(), filters.to?.unix()).then((res) => {
            setPayments(res);
        })
    }, [filters.from, filters.to]);

    useEffect(() => {
        setActivePayments(payments.filter((payment) => {
            return payment.billing_details.email.includes(filters.search) || payment.billing_details.name.includes(filters.search)
        }));
    }, [filters.search, payments]);

    function status(s: string) {
        switch (s) {
            case "succeeded":
                return "Succès";
            case "failed":
                return "Echec";
            default:
                return s;
        }
    }

    return (
        <div>
            <h2>Payments</h2>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Client
                            </TableCell>
                            <TableCell>
                                Plan
                            </TableCell>
                            <TableCell>
                                Statut
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {activePayments.map((rec) => {
                            return (
                                <TableRow style={{color : rec.status === "succeeded" ? 'green' : 'red' }}>
                                    <TableCell onClick={() => console.log(rec)}>
                                        {rec.billing_details.name}{" "}
                                        {rec.billing_details.email}
                                    </TableCell>
                                    <TableCell>
                                        {rec.amount / 100}{""}
                                    </TableCell>
                                    <TableCell>
                                        {status(rec.status)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <ul>
                {payments.map((payment) => {
                    return <li key={payment.id}>{payment.id}</li>
                })}
            </ul> */}
        </div>
    )
}

export function SubscriptionsDashboard({ client, filters }: { client: odooClient, filters: Filters }) {
    const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
    const [activeSubscriptions, setActiveSubscriptions] = React.useState<Subscription[]>([]);
    React.useEffect(() => {
        client.subscriptions(filters.from?.unix(), filters.to?.unix()).then((res) => {
            setSubscriptions(res);
        })
    }, [filters.from, filters.to]);

    useEffect(() => {
        setActiveSubscriptions(subscriptions.filter((subscription) => {
            return subscription.customer.email.includes(filters.search) || subscription.customer.name.includes(filters.search)
        }));
    }, [filters.search, subscriptions]);

    function status(s: string) {
        switch (s) {
            case "active":
                return "Actif";
            case "incomplete_expired":
                return "Expiré";
            default:
                return s;
        }
    }

    return (
        <div>
            <h2>Subscriptions</h2>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Client
                            </TableCell>
                            <TableCell>
                                Date
                            </TableCell>
                            <TableCell>
                                Plan
                            </TableCell>
                            <TableCell>
                                Statut
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {activeSubscriptions.map((rec) => {
                            return (
                                <TableRow >
                                    <TableCell onClick={() => console.log(rec)}>
                                        {rec.customer.name}
                                        {" "}
                                        {rec.customer.email}
                                    </TableCell>
                                    <TableCell>
                                        {dayjs(fromTimestamp(rec.start_date)).format('DD/MM/YYYY')}
                                        <p>
                                            { }
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        {rec.plan.nickname}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            status(rec.status)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <ul>
                {subscriptions.map((subscription) => {
                    return <li key={subscription.id}>{subscription.id}</li>
                })}
            </ul> */}
        </div>
    )
}